<template>
  <a-drawer
    title="结算明细"
    width="800"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 功能条 -->
    <div class="tool-view">
      <!-- 信息 -->
      <span class="hint-info">共选择了<span class="hint-desc">{{ dataSource.length }}</span>天数据，总计分成金额<span class="hint-desc">{{ $pub.KEEP_NUMBER_DECIMAL(totalCashAmount / 100, 2, false, true) }}</span>元。</span>
      <!-- 弹簧 -->
      <div style="flex: 1;"></div>
      <!-- 操作 -->
      <a-button @click="touchDownload">导出数据</a-button>
    </div>
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="false"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 充值金额 -->
        <template v-if="column.key === 'total_amount' || column.key === 'cash_amount' || column.key === 'refund_amount' || column.key === 'divideable_amount'">
          {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
        </template>
        <!-- 分成比例 -->
        <template v-if="column.key === 'cash_rate'">
          {{ `${record.cash_rate || 0}%` }}
        </template>
      </template>
    </a-table>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import * as Ex from "@netjoy/excelex"
import { distributorCashDetailList } from '@/api/copyright'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'

// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 当前数据
let data = ref({})
// 数据源
let dataSource = ref([])
// 总分成金额
let totalCashAmount = ref(0)
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '日期',
    dataIndex: 'data_date',
    key: 'data_date',
  },
  {
    title: '充值金额',
    dataIndex: 'total_amount',
    key: 'total_amount'
  },
  {
    title: '用户退款',
    dataIndex: 'refund_amount',
    key: 'refund_amount'
  },
  {
    title: '可分成金额',
    dataIndex: 'divideable_amount',
    key: 'divideable_amount'
  },
  {
    title: '分成比例',
    dataIndex: 'cash_rate',
    key: 'cash_rate'
  },
  {
    title: '分成金额',
    dataIndex: 'cash_amount',
    key: 'cash_amount'
  }
])

// 显示
function showDrawer (record) {
  // 清空
  totalCashAmount.value = record.cash_amount
  dataSource.value = []
  // 记录
  data.value = record
  // 显示
  visible.value = true
  // 获取数据详情列表
  getList()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    pagination.current = 1
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 获取数据详情列表
function getList () {
  isLoading.value = true
  const params = {
    id: data.value.id
  }
  distributorCashDetailList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 点击导出数据
function touchDownload () {
  if (dataSource.value.length) {
    const columns = [
      {
        name: '日期',
        field: 'data_date'
      },
      {
        name: '充值金额',
        field: 'total_amount'
      },
      {
        name: '用户退款',
        field: 'refund_amount'
      },
      {
        name: '可分成金额',
        field: 'divideable_amount'
      },
      {
        name: '分成比例',
        field: 'cash_rate'
      },
      {
        name: '分成金额',
        field: 'cash_amount'
      }
    ]
    const sheets = [
      {
        data: dataSource.value,
        columns: columns
      }
    ]
    Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
      // row=0 为表头
      if (row != 0) {
        // 金额处理
        if (field === 'total_amount' || field === 'cash_amount' || field === 'refund_amount' || field === 'divideable_amount') {
          item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
          return item
        } else if (field === 'cash_rate') {
          if (item.data != '-') {
            item.data = `${json.cash_rate || 0}%`
          }
          return item
        }
      }
      // 无数据兼容
      // item.data = item.data || 0
      return item
    },  `结算明细`)
  } else {
    message.error('还没有可导出的内容')
  }
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

.hint-info {
  color: #A7A7A7;
}
.hint-desc {
  color: #323232;
}
</style>