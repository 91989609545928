<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>分销管理（旧）</a-breadcrumb-item>
    <a-breadcrumb-item>分销结算单</a-breadcrumb-item>
  </a-breadcrumb>
  <a-tabs class="tabs-view" v-model:activeKey="activeKey">
    <a-tab-pane :key="1" tab="处理中">
      <Ongoing @success="PaySuccess"></Ongoing>
    </a-tab-pane>
    <a-tab-pane :key="2" tab="已打款" force-render>
      <Completed ref="RefCompleted"></Completed>
    </a-tab-pane>
  </a-tabs>
</template>

<script setup>
import { ref } from 'vue'
import Ongoing from './components-reseller-settlement/Ongoing.vue'
import Completed from './components-reseller-settlement/Completed.vue'

// 当前 tab
let activeKey = ref(1)
// 打款成功
let RefCompleted = ref(null)

// 打款成功
function PaySuccess () {
  // 更新列表
  RefCompleted.value.touchSearch()
}

</script>

<style scoped>
.tabs-view {
  margin-top: 20px;
}
</style>